import core from '@/admin/core';

// get
export interface GetRoleResponse {
  roleId: string;
  roleName: string;
  roleSettings: RoleSetting;
  createDate: number;
  isInUse: boolean;
}

// post,put
export interface RoleProps {
  roleName: string;
  roleSettings: RoleSetting;
}

interface RoleSetting {
  [key: string]: { [key: string]: boolean };
}

// 整形_一覧用
export interface RolesItem {
  roleId: string;
  roleName: string;
  isDefault: boolean;
  isInUse: boolean;
}

// 整形_詳細用
export interface RoleItem {
  roleId: string;
  roleName: string;
  roleSettings: RoleItemRoleSettings[];
}

export interface RoleItemRoleSettings {
  contentName: string;
  contentLabel: string;
  settings: { flagName: string; flagLabel: string; caption: string; value: boolean }[];
}

// 自分のロール設定に基づく機能毎の権限 ※key(機能名)はpathと同様のものを使用、機能が追加になった場合、要更新
export interface MyRoleSettings {
  [key: string]: { [key: string]: boolean };
  users: {
    getOnlyWithoutPersonalInfos: boolean;
    get: boolean;
    getUserproperties: boolean;
    createAndUpdateNote: boolean;
    joinSubscriptions: boolean;
    getGifts: boolean;
    addGifts: boolean;
    useGifts: boolean;
    getProducts: boolean;
    useProducts: boolean;
    getFavoriteItems: boolean;
  };
  leave: {
    get: boolean;
  };
  testusers: {
    get: boolean;
    createAndUpdate: boolean;
  };
  administrators: {
    createAndUpdate: boolean;
    delete: boolean;
  };
  forms: {
    get: boolean;
    createAndUpdate: boolean;
    getEntries: boolean;
    getUsers: boolean;
    getOnlyWithoutPersonalInfos: boolean;
    getUserproperties: boolean;
  };
  favorites: {
    get: boolean;
    createAndUpdate: boolean;
    getPermissions: boolean;
    createAndUpdatePermissions: boolean;
    createAndUpdateSettings: boolean;
    deleteSettings: boolean;
    getUsers: boolean;
    getOnlyWithoutPersonalInfos: boolean;
  };
  officialusers: {
    createAndUpdate: boolean;
  };
  talkrooms: {
    get: boolean;
    createAndUpdate: boolean;
    delete: boolean;
    createAndUpdateBannedWords: boolean;
    unrestrictedByLinkedOfficialUsers: boolean;
    getPosts: boolean;
    createAndUpdatePost: boolean;
    deletePost: boolean;
  };
  membershipcards: {
    get: boolean;
    createAndUpdate: boolean;
    delete: boolean;
  };
  messages: {
    unrestrictedByLinkedOfficialUsers: boolean;
    unrestrictedBySubscriptionPlans: boolean;
    get: boolean;
    createAndUpdateDraft: boolean;
    delete: boolean;
    createNotifications: boolean;
  };
  birthdayMessages: {
    unrestrictedByLinkedOfficialUsers: boolean;
    get: boolean;
    createAndUpdateDraft: boolean;
    delete: boolean;
  };
  reactions: {
    createAndUpdate: boolean;
  };
  histories: {
    get: boolean;
    getUsers: boolean;
    getOnlyWithoutPersonalInfos: boolean;
    getUserproperties: boolean;
  };
  permissions: {
    get: boolean;
    createAndUpdate: boolean;
    delete: boolean;
  };
  products: {
    get: boolean;
    createAndUpdate: boolean;
    delete: boolean;
    getPermissions: boolean;
    createAndUpdatePermissions: boolean;
    getHistories: boolean;
    getUsers: boolean;
    getOnlyWithoutPersonalInfos: boolean;
    getUserproperties: boolean;
  };
  plans: {
    createAndUpdate: boolean;
    delete: boolean;
    createAndUpdateProducts: boolean;
  };
  gifts: {
    get: boolean;
    createAndUpdate: boolean;
    delete: boolean;
    addGifts: boolean;
    getConditions: boolean;
    createAndUpdateConditions: boolean;
    deleteConditions: boolean;
    getHistories: boolean;
    getUsers: boolean;
    getOnlyWithoutPersonalInfos: boolean;
    getUserproperties: boolean;
  };
  properties: {
    get: boolean;
    createAndUpdate: boolean;
  };
  settings: {
    createAndUpdate: boolean;
  };
  roles: {
    createAndUpdate: boolean;
    delete: boolean;
  };
  mytalks: {
    unrestrictedByLinkedOfficialUsers: boolean;
    get: boolean;
    createAndUpdate: boolean;
    delete: boolean;
    getMessages: boolean;
    createAndUpdateMessages: boolean;
    createAndUpdateReactions: boolean;
    deleteMessages: boolean;
    getUsers: boolean;
  };
  schedules: {
    createAndUpdate: boolean;
    delete: boolean;
    createAndUpdateSettings: boolean;
  };
  scheduleCategories: {
    createAndUpdate: boolean;
    delete: boolean;
  };
  subscriptionCampaigns: {
    createAndUpdate: boolean;
    delete: boolean;
  };
  counters: {
    createAndUpdate: boolean;
  };
  musics: {
    createAndUpdate: boolean;
    delete: boolean;
  };
  basicAuth: {
    createAndUpdate: boolean;
  };
  report: {
    get: boolean;
  };
  videos: {
    get: boolean;
    createAndUpdate: boolean;
    delete: boolean;
  };
}

// データ※以下ロールに変更があった場合、要更新
// デフォルトのロールのroleId
const defaultRoleIds = ['administrator', 'director', 'viewer'];
export const checkIsDefaultRole = (roleId: string) => {
  return defaultRoleIds.includes(roleId);
};

// 新規作成時のロール設定（値は全てfalse）
export const defaultRoleSettings = {
  adminUsers: {
    createAndUpdate: false,
    delete: false,
  },
  endUsers: {
    getWithoutPersonalInfos: false,
    get: false,
    createAndUpdateNote: false,
  },
  testUsers: {
    createAndUpdate: false,
  },
  forms: {
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  entries: {
    get: false,
  },
  notificationReservations: {
    unrestrictedByLinkedOfficialUsers: false,
    unrestrictedBySubscriptionPlans: false,
    get: false,
    createAndUpdate: false,
    delete: false,
    createNotifications: false,
  },
  birthdayNotificationReservations: {
    unrestrictedByLinkedOfficialUsers: false,
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  officialUsers: {
    createAndUpdate: false,
  },
  histories: {
    get: false,
    createAndUpdate: false,
    use: false,
  },
  leaveReasons: {
    get: false,
  },
  membershipCards: {
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  permissions: {
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  talkRooms: {
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  bannedWords: {
    createAndUpdate: false,
  },
  posts: {
    unrestrictedByLinkedOfficialUsers: false,
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  downloadSignedUrlOfStatData: {
    get: false,
  },
  subscriptions: {
    createAndUpdate: false,
  },
  subscriptionProducts: {
    createAndUpdate: false,
  },
  subscriptionPlans: {
    createAndUpdate: false,
    delete: false,
  },
  subscriptionCampaigns: {
    createAndUpdate: false,
    delete: false,
  },
  products: {
    get: false,
    createAndUpdate: false,
    delete: false,
    purchase: false,
  },
  gifts: {
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  giftConditions: {
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  chatRooms: {
    unrestrictedByLinkedOfficialUsers: false,
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  chatMessages: {
    get: false,
    createAndUpdate: false,
    delete: false,
  },
  reactions: {
    createAndUpdate: false,
    delete: false,
  },
  favoriteItems: {
    get: false,
    createAndUpdate: false,
  },
  scheduleEvents: {
    createAndUpdate: false,
    delete: false,
  },
  scheduleCategories: {
    createAndUpdate: false,
    delete: false,
  },
  counters: {
    createAndUpdate: false,
  },
  musics: {
    createAndUpdate: false,
    delete: false,
  },
  basicAuth: {
    createAndUpdate: false,
  },
  settings: {
    createAndUpdate: false,
  },
  roles: {
    createAndUpdate: false,
    delete: false,
  },
  videos: {
    get: false,
    createAndUpdate: false,
    delete: false,
  },
};
function adjustRoleSettings(roleSettings: RoleSetting) {
  // デフォルトのロールをディープコピー
  const _defaultRoleSettings: RoleSetting = JSON.parse(JSON.stringify(defaultRoleSettings));
  Object.entries(roleSettings).forEach(([key, value]) => {
    // 引数のroleSettingsのうちtrueの権限のみデフォルトのロールを上書き
    Object.entries(value).forEach(([_key, _value]) => {
      if (_value === true) {
        if (!_defaultRoleSettings[key]) _defaultRoleSettings[key] = {};
        _defaultRoleSettings[key][_key] = _value;
      }
    });
  });
  return _defaultRoleSettings;
}

// 管理者権限設定（Role.vue）の表示制御（文言、説明の指定等）
// 設定対象外（非表示）としたい項目は isInactive: true で設定
// TODO: order等必要になれば今後修正検討
export const roleSettingsData: {
  [key: string]: {
    isInactive?: boolean;
    contentName: string;
    settings: { [key: string]: { label: string; caption: string; isInactive?: boolean } };
  };
} = {
  adminUsers: {
    contentName: '管理者',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  endUsers: {
    contentName: 'ユーザー',
    settings: {
      getWithoutPersonalInfos: {
        label: '閲覧（個人情報なし）',
        caption: 'ONにした場合、個人情報を含まない一部のユーザー情報が閲覧可能になります',
      },
      get: {
        label: '閲覧（全て）',
        caption: 'ONにした場合、個人情報を含む全てのユーザー情報が閲覧可能になります',
      },
      createAndUpdateNote: { label: '備考の作成・編集', caption: '' },
    },
  },
  testUsers: {
    contentName: 'テストユーザー',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  forms: {
    contentName: 'フォーム',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  entries: {
    contentName: 'フォーム：投稿',
    settings: {
      get: { label: '閲覧', caption: '' },
    },
  },
  notificationReservations: {
    contentName: 'メッセージBOX',
    settings: {
      unrestrictedByLinkedOfficialUsers: { label: '公式ユーザーの紐付けによる制限を受けない', caption: '' },
      unrestrictedBySubscriptionPlans: {
        label: 'サブスクリプションプランの紐付けによる制限を受けない',
        caption: '',
      },
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集（下書き）', caption: '' },
      delete: { label: '削除', caption: '' },
      createNotifications: { label: '通知予約・即時通知', caption: '' },
    },
  },
  birthdayNotificationReservations: {
    contentName: '誕生日メッセージ',
    settings: {
      unrestrictedByLinkedOfficialUsers: {
        label: '公式ユーザーの紐付けによる制限を受けない',
        caption: '',
        isInactive: true,
      },
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  officialUsers: {
    contentName: '公式ユーザー',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  histories: {
    contentName: '履歴',
    settings: {
      get: {
        label: '閲覧',
        caption: 'ONにした場合、履歴に関連する個別課金商品や特典などのデータも閲覧可能になります',
      },
      createAndUpdate: { label: '作成・編集', caption: 'ONにした場合、ユーザーへの特典の付与が可能になります' },
      use: {
        label: '使用（もぎり）',
        caption: 'ONにした場合、個別課金商品・特典の使用（もぎり）が可能になります',
      },
    },
  },
  leaveReasons: {
    contentName: '退会理由',
    settings: {
      get: { label: '閲覧', caption: '' },
    },
  },
  membershipCards: {
    contentName: '会員証',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  permissions: {
    contentName: '閲覧権限',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  talkRooms: {
    contentName: 'トークルーム',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  bannedWords: {
    contentName: 'NGワード',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  posts: {
    contentName: 'トークルーム：投稿',
    settings: {
      unrestrictedByLinkedOfficialUsers: { label: '公式ユーザーの紐付けによる制限を受けない', caption: '' },
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  downloadSignedUrlOfStatData: {
    contentName: 'レポート',
    settings: {
      get: { label: '閲覧', caption: '' },
    },
  },
  subscriptions: {
    contentName: 'プラン加入',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  subscriptionProducts: {
    contentName: 'プラングループ',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  subscriptionPlans: {
    contentName: 'プラン',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  subscriptionCampaigns: {
    contentName: 'キャンペーン',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  products: {
    contentName: '個別課金',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
      purchase: {
        label: 'エンドユーザーに個別課金商品を購入させる',
        caption: '現在使用できない機能です',
        isInactive: true,
      },
    },
  },
  gifts: {
    contentName: '特典',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  giftConditions: {
    contentName: '特典条件',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  chatRooms: {
    contentName: 'マイトーク',
    settings: {
      unrestrictedByLinkedOfficialUsers: { label: '公式ユーザーの紐付けによる制限を受けない', caption: '' },
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  chatMessages: {
    contentName: 'マイトーク：投稿',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '投稿・リアクション', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  reactions: {
    isInactive: true,
    contentName: 'リアクション',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  favoriteItems: {
    contentName: '推し設定',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  scheduleEvents: {
    contentName: 'スケジュール',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  scheduleCategories: {
    contentName: 'スケジュール：カテゴリー',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  musics: {
    contentName: '楽曲・プレイリスト',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  counters: {
    contentName: '会員番号',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  basicAuth: {
    contentName: 'Basic認証',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  settings: {
    contentName: '設定',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
    },
  },
  roles: {
    contentName: '管理者権限',
    settings: {
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
  videos: {
    contentName: '動画',
    settings: {
      get: { label: '閲覧', caption: '' },
      createAndUpdate: { label: '作成・編集', caption: '' },
      delete: { label: '削除', caption: '' },
    },
  },
};

/**
 * ロール一覧取得API
 *
 * @returns ロール一覧
 */
export const getRoles = async (): Promise<GetRoleResponse[]> => {
  const result = await core.httpClient.get('/admin/public/roles');
  const getRolesResponse = result.data as GetRoleResponse[];
  return getRolesResponse.map((getRoleResponse) => {
    getRoleResponse.roleSettings = adjustRoleSettings(getRoleResponse.roleSettings);
    return getRoleResponse;
  });
};

/**
 * ロール一件取得API
 *
 * @param roleId - 取得したいロールのID
 * @returns 指定したロール情報
 */
export const getRole = async (roleId: string): Promise<GetRoleResponse> => {
  const result = await core.httpClient.get(`/admin/public/roles/${roleId}`);
  const getRoleResponse = result.data as GetRoleResponse;
  getRoleResponse.roleSettings = adjustRoleSettings(getRoleResponse.roleSettings);
  return getRoleResponse;
};

/**
 * ロール登録API
 *
 * @param props - 登録したいロールの情報
 */
export const createRole = async (props: RoleProps) => {
  await core.httpClient.post('/admin/public/roles', props);
};

/**
 * ロール更新API
 *
 * @param roleId - 更新したいロールのID
 * @param props - 更新したいロールの情報
 */
export const editRole = async (roleId: string, props: RoleProps) => {
  await core.httpClient.put(`/admin/public/roles/${roleId}`, props);
};

// export const copyRole = async (roleId: string) => {
//   await core.httpClient.post(`/admin/public/roles/${roleId}`);
// };

/**
 * ロール削除API
 *
 * @param roleId - 削除したいロールのID
 */
export const deleteRole = async (roleId: string) => {
  await core.httpClient.delete(`/admin/public/roles/${roleId}`);
};
